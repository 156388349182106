import React, { useState } from "react";
import { Slider, Typography } from "@mui/material";

interface RatingProportionSliderProps {
  initialValue: number;
  onChangeCommitted: (value: number) => void;
}

export const RatingProportionSlider: React.FC<RatingProportionSliderProps> = ({
  initialValue,
  onChangeCommitted,
}) => {
  const [localValue, setLocalValue] = useState(initialValue);

  return (
    <>
      <Slider
        value={localValue}
        onChange={(_, value) => {
          setLocalValue(value as number);
        }}
        onChangeCommitted={(_, value) => {
          onChangeCommitted(value as number);
        }}
        valueLabelDisplay="auto"
        step={5}
        min={0}
        max={100}
      />
      <Typography variant="caption">
        {localValue}% based on ratings, {100 - localValue}% based on completion
      </Typography>
    </>
  );
};
